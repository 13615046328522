import React from "react"
import { Row, Col, Form, Alert, Spin } from "antd"
import PropTypes from "prop-types"
import { antdHelpers } from "@avldev/gatsby-active-campaign-forms"
import { formUrl } from "../../../config/forms"

import { Name, Email, Phone, Message, Submit } from "./Fields"

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

const formColProps = {
  xs: 24,
  md: 12,
}

class GroupForm extends React.Component {
  formRef = React.createRef()

  static propTypes = {
    buttonIcon: PropTypes.string,
    formName: PropTypes.string.isRequired,
    automationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    gtmClickId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  }

  static defaultProps = {
    buttonIcon: "check",
    formName: "Events Catering",
    automationId: "39",
    gtmClickId: false,
  }

  constructor(props) {
    super(props)
    this.state = {
      buttonIcon: props.icon,
      formSuccess: false,
      formName: props.formName,
      automationId: props.automationId,
      gtmClickId: props.gtmClickId,
      name: "",
    }
  }

  componentDidMount() {
    this.props.form.validateFields()
  }

  formSubmitted = () => {
    this.setState({ formSuccess: true })
  }

  handleSubmit = event => {
    event.preventDefault()

    this.props.form.validateFields(async (err, values) => {
      if (err) {
        console.log(err)
        return
      }

      const { current: form } = this.formRef
      if (!form) return

      const onSubmitStart = () => {
        this.setState({
          buttonIcon: `loading`,
          name: values.name.split(` `)[0],
        })
      }

      const onSubmitEnd = status => {
        this.setState({ formSuccess: status === 200 })
      }

      antdHelpers.onSubmit(
        form,
        { onSubmitStart, onSubmitEnd },
        process.env.NODE_ENV
      )
    })
  }

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } =
      this.props.form
    const nameError = isFieldTouched("name") && getFieldError("name")
    const emailError = isFieldTouched("email") && getFieldError("email")
    const phoneError = isFieldTouched("phone")

    return (
      <>
        {!this.state.formSuccess ? (
          <Spin spinning={this.state.buttonIcon === "loading" ? true : false}>
            <form
              action={formUrl}
              className="content-contact-form"
              onSubmit={this.handleSubmit}
              ref={this.formRef}
              style={{ textAlign: `left` }}
            >
              <Row type="flex" align="top" justify="center" gutter={24}>
                {/* Left Column - Inputs + Range Picker */}
                <Col {...formColProps}>
                  <input type="hidden" name="u" value="9" />
                  <input type="hidden" name="f" value="9" />
                  <input type="hidden" name="s" />
                  <input type="hidden" name="c" value="0" />
                  <input type="hidden" name="m" value="0" />
                  <input type="hidden" name="act" value="sub" />
                  <input type="hidden" name="v" value="2" />
                  <input
                    type="hidden"
                    name="or"
                    value="0b8b4ed61b7877dfae3fe432175b8384"
                  />
                  {/* <UTMParams decorator={getFieldDecorator} /> */}
                  <Name
                    decorator={getFieldDecorator}
                    name="fullname"
                    validator={nameError}
                  />
                  <Email
                    decorator={getFieldDecorator}
                    name="email"
                    validator={emailError}
                  />
                  <Phone
                    decorator={getFieldDecorator}
                    name="phone"
                    validator={phoneError}
                  />
                </Col>
                <Col {...formColProps}>
                  <Message
                    decorator={getFieldDecorator}
                    name="field[4]"
                    placeholder="Please tell us about your event."
                    rows={4}
                    style={{ marginBottom: 12 }}
                  />
                  <Submit
                    id={this.state.gtmClickId}
                    icon={this.state.buttonIcon}
                    disabled={hasErrors(getFieldsError())}
                  />
                </Col>
              </Row>
            </form>
          </Spin>
        ) : (
          <Alert
            message={`Thanks, ${this.state.name}!`}
            description="Your info has been forwarded to our Special Events Director. She will get back to you shortly."
            type="success"
            showIcon
          />
        )}
      </>
    )
  }
}

const EventsForm = Form.create({ name: "events_form" })(GroupForm)

export default EventsForm
