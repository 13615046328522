import React from "react"
import { Col, Divider, Row, Typography } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import Meta from "../components/Meta/Meta"
import SharpHero from "../components/SharpHero/SharpHero"
import Room from "../components/EventRoom/EventRoom"
import Form from "../components/Forms/Events"

const rowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    margin: `auto`,
    maxWidth: 1360,
  },
}

const titleColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
  style: {
    paddingTop: 32,
    paddingBottom: 32,
  },
}

const titleRowProps = {
  align: `middle`,
  gutter: 32,
  justify: `center`,
  type: `flex`,
  style: {
    background: `rgba(247, 247, 247, 1.0)`,
    paddingTop: 64,
  },
}

const formRowProps = {
  align: "middle",
  gutter: 32,
  justify: "center",
  type: "flex",
  style: {
    background: `rgba(247, 247, 247, 1.0)`,
    marginBottom: -64,
    paddingBottom: 64,
  },
}

const formTitleColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
}

const formColProps = {
  xs: 22,
  md: 20,
  lg: 18,
  xl: 16,
  style: {
    marginTop: 32,
  },
}

const formTitleProps = {
  level: 4,
  style: {
    borderBottom: `3px solid darkorange`,
    color: `#fff`,
    fontSize: 32,
    textTransform: `uppercase`,
  },
}

const formProps = {
  formName: "Events Catering",
  automationId: "39",
  gtmClickId: "contact-events-catering-form",
}

export default ({ data }) => {
  const { amphitheater, ballroom, diningRoom, field, gazebo, hero } = data

  const meta = {
    seo: {
      title: "Events | Lakeside Lodge Clemson",
      img: hero.localFile.childImageSharp.fluid.src,
      imgAlt: hero.description,
      desc: `Lakeside Lodge Clemson has a perfect event space for everything from \
        intimate weddings and receptions, bridal and baby showers, family reunions, \
        anniversary parties, birthday celebrations, and even business meetings and \
        trainings.`,
    },
    uri: "/events",
  }

  const heroProps = {
    caption: `Your event. Clemson style.`,
    contentfulAsset: hero,
    imageStyle: { filter: `brightness(0.85)` },
  }

  const eventSpaces = [
    {
      title: "The Lakeside Ballroom",
      description:
        "The perfect venue for a reception, shower, reunion, or any mid-size celebration. We’ll provide all you need for a successful event, including the catering, linens, and dinnerware.",
      capacity: 110,
      features: [],
      fluidAsset: ballroom,
    },
    {
      title: "Amphitheater",
      description:
        "Catch up on some reading al fresco, have a picnic, or enjoy an evening string quartet performance with friends and family. R&R is always better under the sun and stars.",
      capacity: "Please Call",
      features: ["Base Platform", "Stage", "Grass", "240V Electric"],
      fluidAsset: amphitheater,
    },
    {
      title: "Mini Football Field",
      description:
        "From flag football games to screen-on-the-greens to unforgettable tailgates, this miniaturized, Clemson-themed football field will become a favorite hangout spot for you and your family.",
      capacity: 300,
      features: [],
      fluidAsset: field,
    },
    {
      title: "Private Dining Room",
      description:
        "A cozy, intimate spot for a catered gathering of close friends or extended family. Hidden behind the fireplace.",
      capacity: 16,
      features: [],
      fluidAsset: diningRoom,
    },
    {
      title: "Gazebo",
      description:
        "Gather outdoors under the shade for a wine and cheese party, commitment ceremony, or just to relax away from the sun’s rays.",
      capacity: 24,
      features: [],
      fluidAsset: gazebo,
    },
  ]

  return (
    <Shell>
      <Meta {...meta} />
      <SharpHero {...heroProps} />
      <Row {...rowProps}>
        <Col {...titleColProps}>
          <Typography.Title level={1}>
            The Best of Clemson Awaits
          </Typography.Title>
          <Typography.Paragraph>
            Lakeside Lodge Clemson has a perfect event space for everything from
            intimate weddings and receptions, bridal and baby showers, family
            reunions, anniversary parties, birthday celebrations, and even
            business meetings and trainings.
          </Typography.Paragraph>
          <Typography.Paragraph>
            No other venue comes as close to understanding Clemson and Tiger
            Pride as we do; it’s in our blood, it’s in our hearts, it’s in our
            bones, and we want to share it with you and your loved ones. Your
            event doesn’t need to have an orange and purple color scheme, just
            the spirit of the paw.
          </Typography.Paragraph>
        </Col>
      </Row>
      <Row {...rowProps}>
        <Col {...titleColProps}>
          {eventSpaces.map((space, index) => (
            <div key={`${space.title}-${index}`}>
              <div style={{ margin: `24px auto` }}>
                <Room {...space} />
              </div>
              {index + 1 < eventSpaces.length ? <Divider /> : <></>}
            </div>
          ))}
        </Col>
      </Row>
      <Row {...titleRowProps}>
        <Col {...formTitleColProps}>
          <Typography.Title {...formTitleProps}>
            Book Your Event Today
          </Typography.Title>
        </Col>
      </Row>
      <Row {...formRowProps}>
        <Col {...formColProps}>
          <Form {...formProps} />
        </Col>
      </Row>
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(contentful_id: { eq: "7hzWpel3woi7vu2GpfPLkw" }) {
      description
      localFile {
        childImageSharp {
          fluid(jpegProgressive: true, jpegQuality: 80, webpQuality: 80) {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
          }
        }
      }
    }
    ballroom: contentfulAsset(title: { eq: "LLC - Ballroom" }) {
      description
      fluid(maxWidth: 350) {
        ...fluid_withWebp_tracedSVG
      }
    }
    diningRoom: contentfulAsset(
      title: { eq: "Traditions - Private Dining Room" }
    ) {
      description
      fluid(maxWidth: 350) {
        ...fluid_withWebp_tracedSVG
      }
    }
    amphitheater: contentfulAsset(
      contentful_id: { eq: "6Jv4orbPjetmtgBZOtyfwo" }
    ) {
      description
      fluid(maxWidth: 350) {
        ...fluid_withWebp_tracedSVG
      }
    }
    field: contentfulAsset(contentful_id: { eq: "5URfMUTgXPuifBai693Zct" }) {
      description
      fluid(maxWidth: 350) {
        ...fluid_withWebp_tracedSVG
      }
    }
    gazebo: contentfulAsset(contentful_id: { eq: "30FHQw0H7cRqe0Y14Gj3JC" }) {
      description
      fluid(maxWidth: 350) {
        ...fluid_withWebp_tracedSVG
      }
    }
  }
`
