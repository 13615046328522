import React from "react"
import { Col, Icon, List, Row, Tooltip, Typography } from "antd"
import Img from "gatsby-image"
import style from "./EventRoom.module.scss"

const EventRooms = ({ capacity, description, features, fluidAsset, title }) => {
  const rowProps = {
    align: "middle",
    gutter: 24,
    justify: "center",
    type: "flex",
  }

  const colProps = {
    xs: 24,
    sm: 12,
  }

  const imgProps = {
    alt: fluidAsset.description,
    className: style.image,
    fluid: fluidAsset.fluid,
  }

  const nestedRowProps = {
    align: "middle",
    justify: "start",
    type: "flex",
  }

  const titleProps = {
    className: style.title,
    level: 2,
    style: {
      fontSize: 26,
      marginBottom: 8,
    },
  }

  const descriptionProps = {
    className: style.description,
  }

  const capacityIcons = capacity => {
    let num = isNaN(capacity) ? 2 : Math.round(((capacity / 50) % 5) * 2) / 2
    let icons = [<Icon className={style.icon} type="user" />]
    for (let i = 1; i < num; i++) {
      icons.push(
        <Icon
          className={`${style.icon} ${num % 1 > 0 ? style.half : style.whole}`}
          type="user"
        />
      )
    }
    return icons
  }

  const listFeatures = features => {
    const listProps = {
      className: style.features,
      dataSource: features,
      grid: {
        column: 2,
        gutter: 16,
      },
      renderItem: feature => (
        <List.Item className={style.feature}>{feature.trim()}</List.Item>
      ),
    }

    return features.length ? <List {...listProps} /> : <></>
  }

  return (
    <Row {...rowProps}>
      <Col {...colProps}>
        <Img {...imgProps} />
      </Col>
      <Col {...colProps}>
        <Row {...nestedRowProps}>
          <Col className={style.titleCol}>
            <Typography.Title {...titleProps}>{title}</Typography.Title>
          </Col>
          <Col className={style.capacityCol}>
            <Tooltip title={`Capacity: ${capacity}`}>
              {capacityIcons(capacity)}
            </Tooltip>
          </Col>
        </Row>
        <Typography.Paragraph {...descriptionProps}>
          {description}
        </Typography.Paragraph>
        <Typography.Paragraph>{listFeatures(features)}</Typography.Paragraph>
      </Col>
    </Row>
  )
}

export default EventRooms
